import React from "react";
import footerlogo from "../assetsgeneral/images/footerlogo.png";

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="footer__container">
        {/* Logo Section */}
        <div className="footer__logo">
          <a href="/">
            <img src={footerlogo} alt="Phat Thai Logo" />
          </a>
        </div>

        {/* Social Media */}
        <div className="footer__social">
          <ul>
            <li>
              <a href="https://www.instagram.com/phatthaise19?igsh=MWIzMHNvb2g5Ym1qcA==">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>

        {/* Contact Info */}
        <div className="footer__contact">
          <h2>Contact Us</h2>
          <ul>
            <li>
              <a href="mailto:info@phatthai.co.uk">
                <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                info@phatthai.co.uk
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer__bottom">
        <div>
          Copyrights 2024 PhatThai. All Rights Reserved. Developed and Designed
          By
          <a
            href="https://systemdecoding.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            System Decoding
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
