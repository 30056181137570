import React from "react";
import aboutus from "../../assetsgeneral/images/aboutus.png";
import aboutus2 from "../../assetsgeneral/images/aboutus2.png";
const AboutUsInfo = () => {
  return (
    <>
      <div className="about-us-section" id="about-us-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="about-uss-text">
                <br />
                <br />
                <br />
                <br />
                <h2 style={{ fontFamily: "Georgia, serif" }}> About Us </h2>
                <p style={{ fontFamily: "Georgia, serif" }}>
                  Inspired by the bustling markets and delicious street food of
                  Thailand and Japan, Phat Thai brings the vibrant flavors of
                  modern East Asia to South London. The name "Phat Thai"
                  translates to "Order and taste" in Thai. At Phat Thai, you can
                  enjoy a variety of dishes such as curries, stir fries,
                  noodles, sushi, salads, and more, all carefully crafted using
                  recipes perfected by Asian food experts.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="about-uss-img">
                <img
                  src={aboutus}
                  id="about-img"
                  style={{ borderRadius: "30px 0 0 30px", height: "460px" }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="about-uss-img">
                <img
                  src={aboutus2}
                  id="about-img"
                  style={{ borderRadius: "0 30px 30px 0", height: "460px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUsInfo;
