import React from 'react'
import Home from './pages/Home'
import { Routes, Route } from 'react-router-dom'
// import "./App.css"
// import "./style.css"
import "./assetsgeneral/css/style.css"
import "./assetsgeneral/css/font-awesome.css"
import "./assetsgeneral/css/font-awesome.min.css"
// import "./assetsgeneral/css/login.css"
import "./assetsgeneral/fonts/fontawesome-webfont.eot"
import "./assetsgeneral/fonts/fontawesome-webfont.svg"
import "./assetsgeneral/fonts/fontawesome-webfont.ttf"
import "./assetsgeneral/fonts/fontawesome-webfont.woff"
import "./assetsgeneral/fonts/fontawesome-webfont.woff2"
import AboutUs from './pages/AboutUs'
import OurPlans from './pages/OurPlans'
import WeWork from './pages/WeWork'
import ContactUs from './pages/ContactUs'
import Login from './pages/Login'

// import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle"

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AboutUs />} />
        {/* <Route path="/about-us" element={<AboutUs />} /> */}
        {/* <Route path="/our-plan" element={<OurPlans />} /> */}
        {/* <Route path="/we-work" element={<WeWork />} /> */}
        {/* <Route path="/contact" element={<ContactUs />} /> */}
        {/* <Route path="/login" element={<Login />} /> */}
      </Routes>
    </>
  )
}
export default App