import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../src/assetsgeneral/images/logo.png";
import { useSelector } from "react-redux";

const ResponsiveNavBar = () => {
  const myState = useSelector((state) => state.changeTheTab);
  const [isOpen, setIsOpen] = useState(false);

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    closeNavbar();
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg bg-dark custom-navbar">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img src={logo} className="navbar-logo" alt="Logo" />
        </NavLink>
        <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
          <i
            className={`fa ${isOpen ? "fa-times" : "fa-bars"}`}
            aria-hidden="true"
          ></i>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => scrollToSection("about-us-section")}
              >
                About Us
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => scrollToSection("footer")}
              >
                Contact Us
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default ResponsiveNavBar;
